import {request} from '@/common/request.js'

export const wechat = {
    jssdk(params) {
        return request.get('/api/wechat/jssdk', {params})
    }
}

export const config = {
    collect() {
        return request.get('/api/config/collect')
    }
}

export const auth = {
    login(data) {
        return request.post('/api/auth/login', data)
    }
}

export const views = {
    log(data) {
        return request.post('/api/view/log', data)
    }
}

export const user = {
    info(params) {
        return request.get('/api/user/info', {params})
    },
    setStep(data) {
        return request.post('/api/user/setStep', data)
    },
    setAnswer(data) {
        return request.post('/api/user/setAnswer', data)
    },
    checkBook(data) {
        return request.post('/api/user/checkBook', data)
    }
}

export const article = {
    get(params) {
        return request.get('/api/article/get', {params})
    }
}

export const lottery = {
    start(data) {
        return request.post('/api/lottery/start', data)
    },
    log(params) {
        return request.get('/api/lottery/log', {params})
    },
    check(data) {
        return request.post('/api/lottery/check', data)
    }
}

export const prize = {
    all(params) {
        return request.get('/api/prize/all', {params})
    }
}

export const task = {
    create(data) {
        return request.post('/api/task/create', data)
    },
    log(params) {
        return request.get('/api/task/log', {params})
    },
}

export const question = {
    all(params) {
        return request.get('/api/question/all', {params})
    },
    submit(data) {
        return request.post('/api/question/submit', data)
    }
}

